import React from "react";
import { useCategory } from "@elevio/kb-kit/lib/hooks";
import * as Article from "@elevio/kb-kit/lib/components/article";
import { faqArticleIds } from "../utils/constants";
function ArticleList({ className, categoryId, isRelated, isAside }) {
    // const { totalCount } = useArticles();
    // const [limit, setLimit] = useState(totalCount);
    const category = useCategory({ usePageCategory: false });
    // if (totalCount === 0) return null;
    return (<div className={className}>
      <h3 className={!isRelated ? 'home-article-title' : ''}>
      {isRelated ? 'Related Articles' : category && category.title}
        {/* <CategoryTitle > {category && category.title} </CategoryTitle> */}
        {/* <Trans i18nKey="article.relatedTitle">></Trans> */}
      </h3>
      <ul className="article-list">

        {/* <Articles.Loop categoryId="24">
          <>
            <Article.IsNotActive>
              <li className="article-list-item home-article-info">
                <Article.Link articleId="" className="article-list-link">
                  <Article.Title articleId="" />
                </Article.Link>
              </li>
            </Article.IsNotActive>
          </>
        </Articles.Loop> */}

        {faqArticleIds.map(article => (<Article.IsNotActive>
          <li className="article-list-item home-article-info">
          <Article.Link articleId={article} className="article-list-link">
            <Article.Title articleId={article}/>
          </Article.Link>
        </li>
        </Article.IsNotActive>))}

        {/* <li className="article-list-item">
          {limit < totalCount && <a className="article-list-link article-count" href="#" onClick={(e) => {
              e.preventDefault();
              setLimit(totalCount)
            }}>View all {isAside ? totalCount - 1 : totalCount} articles</a>}
        </li> */}
      </ul>
    </div>);
}
export default ArticleList;
