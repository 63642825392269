import { HomeLink } from '@elevio/kb-kit/lib/components/meta';
import * as SearchResults from '@elevio/kb-kit/lib/components/searchResults';
import { Trans } from '@elevio/kb-kit/lib/components/trans';
import { useSearchResults, useTranslation } from '@elevio/kb-kit/lib/hooks';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { MainCentered } from '../components/layouts/Main';
import PageLayout from '../components/layouts/Page';
import SearchMore from '../components/SearchMore';
import SearchResult from '../components/SearchResult';
const Page = () => {
    const { t } = useTranslation();
    const { searchTerm, totalCount } = useSearchResults();
    return (<PageLayout header={<Header hideSearch>
          <div className="header-container"/>
        </Header>} searchCount={<div className="search-count">
          <SearchResults.IsNotEmpty>
            <p>
              <Trans i18nKey="search.count" count={totalCount || 0}>
                We found {{ count: totalCount }} articles for{' '}
                <strong>"{{ searchTerm }}"</strong>
              </Trans>
            </p>
          </SearchResults.IsNotEmpty>
        </div>} footer={<Footer />}>
      <MainCentered>
        <SearchResults.LoopResults>
          <SearchResult />
        </SearchResults.LoopResults>

        <SearchResults.IsEmpty>
          <div className="search-empty">
            <h3 className="search-empty-title">
              <Trans i18nKey="search.noResults">
                No results were found when searching for “
                <SearchResults.SearchTerm />
                ”. Please try another search term. For additional assistance,
                call the City of Soledad during business hours at{' '}
                <a href="tel:%831-755-5111" target="_blank">
                  831-755-5111
                </a>{' '}
                or visit{' '}
                <a href="https://www.cityofsoledad.com/" target="_blank">
                  cityofsoledad.in.gov"
                </a>
              </Trans>
            </h3>
            <p className="search-empty-message">
              <Trans i18nKey="search.tryAgain">
                Try another search or <HomeLink>Browse by category</HomeLink>
              </Trans>
            </p>
          </div>
        </SearchResults.IsEmpty>
        <SearchMore />
      </MainCentered>
    </PageLayout>);
};
export default Page;
