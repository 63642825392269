import { fetchArticle } from '@elevio/kb-kit/lib/api/article';
import { Categories } from '@elevio/kb-kit/lib/components';
import * as Meta from '@elevio/kb-kit/lib/components/meta';
import { useCategories } from '@elevio/kb-kit/lib/hooks';
import cx from 'classnames';
import { useEffect, useRef, useState } from 'react';
import PhoneInput from 'react-phone-number-input/input';
import ArticleList from '../components/ArticleList';
import Footer from '../components/Footer';
import Header from '../components/Header';
import HomeArticles from '../components/HomeArticles';
import { MainSection } from '../components/layouts/Main';
import PageLayout from '../components/layouts/Page';
import Breadcrumb from '../components/PageBreadcrumbs';
import { getSubmissions } from '../requests/user-submission';
import { DEFAULT_CRM_TOKEN, homeArticleIds } from '../utils/constants';
import Validator from '../utils/Validators';
const isIOS = () => {
    return navigator.userAgent.match(/iPhone/i) != null;
};
const validator = new Validator();
export const getHeader = () => {
    return (<Header hideSearch breadcrumbs={<div className="breadcrumbs-header-wrapper">
          <Breadcrumb className="breadcrumbs-header"/>
        </div>}>
      <div className="header-container"/>
    </Header>);
};
const HomePage = () => {
    const { categories } = useCategories();
    const [displayRequestField, setDisplayRequestField] = useState(false);
    const [contactInfo, setContactInfo] = useState('');
    const [displayRequestFieldType, setDisplayRequestFieldType] = useState('EMAIL');
    const [errorMessage, setErrorMessage] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [articles, setArticles] = useState([]);
    const requestSubmissionContainer = useRef(document.createElement('div'));
    const requestSubmissionContainerMobile = useRef(document.createElement('div'));
    useEffect(() => {
        document.addEventListener('mousedown', handleClick);
        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, []);
    useEffect(() => {
        const fetchArticles = async () => {
            try {
                const responses = await Promise.all(homeArticleIds.map((id) => fetchArticle(id, '1')));
                setArticles(responses);
            }
            catch (error) {
                console.error('Error fetching articles:', error);
            }
        };
        fetchArticles();
    }, []);
    const isMobile = () => {
        return (navigator.userAgent.match(/Android/i) != null ||
            navigator.userAgent.match(/iPhone/i) != null);
    };
    const handleClick = (e) => {
        if (isMobile() &&
            requestSubmissionContainerMobile.current &&
            !requestSubmissionContainerMobile.current.contains(e.target)) {
            setErrorMessage('');
            setDisplayRequestField(false);
            setResponseMessage('');
        }
        else if (!isMobile() &&
            requestSubmissionContainer.current &&
            !requestSubmissionContainer.current.contains(e.target)) {
            setErrorMessage('');
            setDisplayRequestField(false);
            setResponseMessage('');
        }
    };
    const toggleRequestField = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (displayRequestField) {
            setErrorMessage('');
        }
        setDisplayRequestField(!displayRequestField);
    };
    const getRequestFieldTypeHandler = (type) => {
        return () => {
            setDisplayRequestFieldType(type);
            setErrorMessage('');
        };
    };
    const isEmailSelected = displayRequestFieldType === 'EMAIL';
    const isPhoneNumberSelected = displayRequestFieldType === 'PHONE_NUMBER';
    const onRequestSubmissionClick = () => {
        setErrorMessage('');
        if (contactInfo &&
            (validator.isEmail(contactInfo) ||
                validator.isPhone(contactInfo))) {
            const token = process.env.CRM_TOKEN || DEFAULT_CRM_TOKEN;
            setLoading(true);
            getSubmissions(token, contactInfo)
                .then((result) => {
                setContactInfo('');
                setResponseMessage(result.message);
                setDisplayRequestField(false);
            })
                .catch((error) => {
                var _a, _b;
                setErrorMessage((_b = (_a = error === null || error === void 0 ? void 0 : error.message) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : 'Failed loading submissions');
            })
                .finally(() => setLoading(false));
        }
        else {
            let errorMessage = `Incorrect ${isEmailSelected ? 'email' : 'mobile number'} format`;
            if (!contactInfo || (contactInfo && !contactInfo.trim())) {
                errorMessage = 'The email or mobile number is required.';
            }
            setErrorMessage(errorMessage);
        }
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onRequestSubmissionClick();
        }
    };
    const handleChangeEmail = (event) => {
        var _a;
        const value = (_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.value;
        setContactInfo(value);
    };
    return (<PageLayout header={getHeader()} footer={<Footer />}>
      <MainSection className="page-text-color">
        <section className="button-container">
          <div className="button-wrapper">
            <Meta.ExtraPageLink path="submitRequest" className="cta">
              Submit a Request
            </Meta.ExtraPageLink>
          </div>
          <div ref={isMobile()
            ? requestSubmissionContainerMobile
            : requestSubmissionContainer} className="button-wrapper">
            <button className="cta-request" onClick={toggleRequestField}>
              View your Requests
            </button>
            {displayRequestField && (<div className="request-field-dropdown">
                <div className="request-field-dropdown-text">
                  View your submission(s) by entering your associated email or
                  phone number:
                </div>
                <div className="request-input-type-toggle-section">
                  <button className={cx('request-input-type-toggle-button', {
                selected: isEmailSelected,
            })} disabled={loading} onClick={getRequestFieldTypeHandler('EMAIL')}>
                    Email
                  </button>
                  <button className={cx('request-input-type-toggle-button', {
                selected: isPhoneNumberSelected,
            })} disabled={loading} onClick={getRequestFieldTypeHandler('PHONE_NUMBER')}>
                    Phone Number
                  </button>
                </div>
                {isEmailSelected && (<div className="request-input">
                    <input type="email" placeholder="Enter your email address" onKeyDown={handleKeyDown} onChange={handleChangeEmail}/>
                    {errorMessage && (<div id="error-message">{errorMessage}</div>)}
                  </div>)}
                {isPhoneNumberSelected && (<div className="request-input">
                    <PhoneInput placeholder={'(xxx) xxx-xxxx'} defaultCountry="US" onKeyDown={handleKeyDown} onChange={setContactInfo}/>
                    {errorMessage && (<div id="error-message">{errorMessage}</div>)}
                  </div>)}
                <hr className="request-field-hr"></hr>
                <div className="request-action">
                  <button disabled={!contactInfo} onClick={onRequestSubmissionClick}>
                    Submit
                  </button>
                </div>
              </div>)}
            {responseMessage && (<div className="request-field-dropdown response-message-container">
                <div className="response-message">
                  <span onClick={() => setResponseMessage('')}>x</span>
                  <div>{responseMessage}</div>
                </div>
              </div>)}
          </div>
        </section>
        <section className="article-container">
          <div className="view-requests-section">
            <HomeArticles articles={articles}/>
          </div>
          <div className="view-requests-section">
            <Categories.Loop limit={1}>
              <ArticleList />
            </Categories.Loop>
          </div>
        </section>
      </MainSection>
    </PageLayout>);
};
export default HomePage;
